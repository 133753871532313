<template>
  <div style="padding-right: 3.4em">
    <v-snackbar v-model="showsnackbar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card class="mb-1">
                <v-card-title>Add Invoice</v-card-title>
                <v-progress-linear
                  :active="isLoading"
                  indeterminate
                  absolute
                  bottom
                  color="primary"
                ></v-progress-linear>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <!-- <v-text-field
                        color="black"
                        label="ABB Order No."
                        outlined
                        dense
                        v-model="invoice.orderId"
                      ></v-text-field> -->
                      <v-autocomplete
                        v-model="orderId"
                        :items="idlistItems"
                        item-text="orderID"
                        item-value="_id"
                        dense
                        color="black"
                        label="ABB Order No."
                        outlined
                        :search-input.sync="searchorderId"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      md="3"
                      style="padding-bottom: 0px !important"
                    >
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="invoiceDate"
                            label="Invoice Date"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="invoice.invoiceDate"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      md="3"
                      style="padding-bottom: 0px !important"
                    >
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="shippingDate"
                            label="Shipping Date"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="invoice.shippingDate"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Customer P.O"
                        outlined
                        dense
                        v-model="invoice.customerPO"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <!-- <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 13px">Billing Address</span>
                  </v-row> -->
                  <v-row dense>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <!-- <v-text-field
                        color="black"
                        label="Bill to company"
                        outlined
                        dense
                        v-model="invoice.billToCompany"
                      ></v-text-field> -->
                      <v-autocomplete
                        v-model="billToCompany"
                        :items="listItems"
                        item-text="billToCompany"
                        item-value="_id"
                        dense
                        color="black"
                        label="Bill to company"
                        outlined
                        :search-input.sync="search"
                      ></v-autocomplete>
                      <v-dialog
                        v-model="recentOrder"
                        max-width="600px"
                        scrollable
                      >
                        <v-card>
                          <v-card-title class="headline grey lighten-2">
                            ABB Labels
                          </v-card-title>
                          <v-card-actions>
                            <v-layout pb-5 wrap justify-center>
                              <v-flex xs12 md12 pt-4 text-center>
                                <v-btn
                                  color="info"
                                  class="mr-4"
                                  @click="getrecentList(billToCompany)"
                                >
                                  Recent Orders
                                </v-btn>
                                <v-btn @click="formreset">
                                  Keep It fresh
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </v-card-actions>
                          <v-divider></v-divider>
                          <v-card-text style="height: 300px">
                            <v-list v-if="recentlist.length > 0" rounded>
                              <v-list-item-group color="primary">
                                <v-list-item
                                  v-for="(item, i) in recentlist"
                                  :key="i"
                                >
                                  <v-list-item-content
                                    @click="getrecentOrder(item)"
                                  >
                                    <v-list-item-title
                                      >Order No : {{ item.orderID }} | Date :
                                      {{ ChangeFormateDate(item.orderDate) }} |
                                      Company : {{ item.billToCompany }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                            <v-list v-else>
                              <v-list-item>
                                <v-list-item-title class="text-center">
                                  <span style="color: red">
                                    No recent orders found!
                                  </span>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Address"
                        outlined
                        dense
                        v-model="invoice.billToAddress"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="City"
                        outlined
                        dense
                        v-model="invoice.billToCity"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1" style="padding-bottom: 0px !important">
                      <v-select
                        ref="state"
                        color="black"
                        label="State"
                        outlined
                        dense
                        v-model="invoice.billToStateId"
                        :items="states"
                        item-text="alphaCode"
                        item-value="_id"
                      ></v-select>
                    </v-col>
                    <v-col md="2" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Zip"
                        outlined
                        dense
                        v-model="invoice.billToZip"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 13px">Shipping Address</span>
                  </v-row> -->

                  <v-row dense>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Bill to company"
                        outlined
                        dense
                        v-model="invoice.shipToCompany"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Address"
                        outlined
                        dense
                        v-model="invoice.shipToAddress"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="City"
                        outlined
                        dense
                        v-model="invoice.shipToCity"
                      ></v-text-field>
                    </v-col>

                    <v-col md="1" style="padding-bottom: 0px !important">
                      <v-select
                        :items="states"
                        ref="state"
                        color="black"
                        label="State"
                        outlined
                        dense
                        v-model="invoice.shipToStateId"
                        item-text="alphaCode"
                        item-value="_id"
                      ></v-select>
                    </v-col>
                    <v-col md="2" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Zip"
                        outlined
                        dense
                        v-model="invoice.shipToZip"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                    <v-col md="2" style="padding-bottom: 0px !important">
                      <v-text-field
                        label="Ship Via"
                        v-model="invoice.shipMethodId"
                        item-text="name"
                        item-value="_id"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="2" style="padding-bottom: 0px !important">
                      <v-text-field
                        ref="terms"
                        color="black"
                        label="Terms"
                        outlined
                        v-model="invoice.terms"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="2" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Agent"
                        outlined
                        dense
                        disabled
                        v-model="agentCode"
                      ></v-text-field>
                    </v-col>

                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Phone"
                        outlined
                        dense
                        v-model="phoneno"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Email"
                        outlined
                        dense
                        v-model="invoice.email"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col md="3">
                      <v-text-field
                        color="black"
                        label="Customer No"
                        outlined
                        dense
                        v-model="invoice.customerId"
                      ></v-text-field>
                    </v-col> -->
                  </v-row>

                  <!-- <v-row dense>
                    <v-col md="3">
                      <v-text-field
                        color="black"
                        label="Phone"
                        outlined
                        dense
                        v-model="invoice.phone"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        color="black"
                        label="Email"
                        outlined
                        dense
                        v-model="invoice.email"
                      ></v-text-field>
                    </v-col>
                  </v-row> -->
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense style="width: 100% !important">
                    <v-col md="6" class="text-left"
                      ><p class="pt-4">Invoices</p>
                    </v-col>
                    <v-col
                      v-if="productinfo.length == 0"
                      md="6"
                      class="text-right pt-0"
                      ><v-btn
                        fab
                        small
                        depressed
                        color="primary"
                        @click="update"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn></v-col
                    >
                  </v-row>

                  <v-row dense v-for="(item, i) in productinfo" :key="i">
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Order Qty"
                        outlined
                        dense
                        v-model="item.quantity"
                        :rules="qtyrules"
                        v-on:input="amtComputation(item)"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Ship Qty"
                        outlined
                        dense
                        :rules="shipqtyrules"
                        v-model="item.shipQuantity"
                        v-on:input="amtComputation(item)"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        color="black"
                        label="Description"
                        outlined
                        dense
                        :rules="descriptionrule"
                        v-model="item.description"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Unit Price"
                        outlined
                        dense
                        v-model="item.unitPrice"
                        :rules="untPrice"
                        v-on:input="amtComputation(item)"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Um"
                        outlined
                        dense
                        v-model="item.um"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1" class="text-right;">
                      <v-checkbox
                        v-model="item.isTaxable"
                        label="Tx"
                        v-on:change="amtComputation(item)"
                      ></v-checkbox>
                    </v-col>
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Amount"
                        outlined
                        dense
                        disabled
                        v-model="item.amount"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col md="1">
                      <v-file-input
                        hide-input
                        
                      ></v-file-input>
                    </v-col> -->
                    <v-col md="1">
                      <v-btn
                        class="mx-2"
                        depressed
                        fab
                        dark
                        small
                        color="error"
                        @click="removee(i)"
                      >
                        <v-icon dark> mdi-minus </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col md="1">
                      <v-btn
                        v-if="i == productinfo.length - 1"
                        fab
                        depressed
                        small
                        color="primary"
                        @click="update"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- <v-row dense style="width:100%!important">
                     <v-col md="12" class="text-right pt-0"
                      ><v-btn fab depressed color="primary" @click="update">
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn></v-col >
                  </v-row> -->
                  <v-form ref="amtrow" lazy-validation>
                    <v-row dense>
                      <v-col md="2">
                        <v-text-field
                          color="black"
                          label="Subtotal"
                          outlined
                          dense
                          disabled
                          v-model="estTotal"
                        ></v-text-field>
                      </v-col>
                      <v-col md="2">
                        <v-text-field
                          color="black"
                          label="Sale Tax"
                          outlined
                          dense
                          disabled
                          v-model="salesTax"
                        ></v-text-field>
                      </v-col>
                      <v-col md="1">
                        <v-text-field
                          color="black"
                          label="Freight"
                          outlined
                          dense
                          v-model="freight"
                          v-on:input="balanceComputation()"
                        ></v-text-field>
                      </v-col>
                      <v-col md="1">
                        <v-text-field
                          color="black"
                          label="Discount"
                          outlined
                          dense
                          v-on:input="balanceComputation()"
                          v-model="discount"
                        ></v-text-field>
                      </v-col>
                      <v-col md="2">
                        <v-text-field
                          color="black"
                          label="Payment"
                          outlined
                          dense
                          v-on:input="balanceComputation()"
                          v-model="payment"
                        ></v-text-field>
                      </v-col>
                      <v-col md="2">
                        <v-text-field
                          color="black"
                          label="Total"
                          outlined
                          dense
                          disabled
                          v-model="amttotal"
                        ></v-text-field>
                      </v-col>

                      <v-col md="2">
                        <v-text-field
                          color="black"
                          label="Balance"
                          outlined
                          dense
                          disabled
                          v-model="balance"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 14px"><b>Notes</b></span>
                  </v-row>

                  <v-row dense>
                    <v-col md="2">
                      <v-row dense>
                        <v-col md="12">
                          <v-text-field
                            ref="Agent"
                            color="black"
                            label="Agent"
                            dense
                            disabled
                            outlined
                            v-model="agentCode"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="10">
                      <v-row dense v-for="(item, i) in notes" :key="i">
                        <v-col md="10">
                          <v-text-field
                            ref="Notes"
                            color="black"
                            label="Note"
                            dense
                            outlined
                            v-model="item.note"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col md="1">
                          <v-btn
                            v-if="i > 0"
                            class="mx-2"
                            depressed
                            fab
                            dark
                            small
                            color="error"
                            @click="notesremovee(i)"
                          >
                            <v-icon dark> mdi-minus </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col md="1">
                          <v-btn
                            v-if="i == notes.length - 1"
                            fab
                            depressed
                            small
                            color="primary"
                            @click="notesupdate"
                          >
                            <v-icon dark> mdi-plus </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
          <v-card class="mb-4">
            <v-layout pb-5 wrap justify-center>
              <v-flex xs12 md12 pt-4 text-center>
                <v-btn
                  dark
                  color="primary"
                  depressed
                  class="text-capitalize"
                  :ripple="false"
                  @click="addValidate"
                >
                  Submit
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
    <v-navigation-drawer
      style="position: fixed; padding-top: 5%"
      right
      width="55"
    >
      <v-list nav dense>
        <v-list-item link to="" title="Excel">
          <v-list-item-icon>
            <v-icon color="primary">mdi-file-excel</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
        <v-list-item link title="Pdf">
          <v-list-item-icon>
            <v-icon color="primary">mdi-file-pdf-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item link to="/addInvoice" title="Add Invoice">
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["orderid"],
  data() {
    return {
      search: "",
      listItems: [],
      recentlist: [],
      idlistItems: [],
      billToCompany: null,
      cusId: null,
      recentOrder: false,
      invoiceData: null,
      orderId: null,
      searchorderId: null,
      invoice: {
        billToCompany: null,
        billToAddress: null,
        billToStateId: null,
        billToCity: null,
        billToZip: null,
        shipToCompany: null,
        shipToAddress: null,
        shipToStateId: null,
        shipToCity: null,
        shipToZip: null,
        terms: null,
        email: null,
        phone: null,
        customerPO: null,
        customerId: null,
        shipMethodId: null,
        orderId: null,
        invoiceDate: new Date().toISOString().substr(0, 10),
        shippingDate: "",
        invoiceProducts: [],
        freight: "",
        discount: "",
        payment: "",
      },
      states: [],
      terms: [],
      shipvia: [],
      menu1: false,
      modal: false,
      menu2: false,
      dialog: false,
      dialogDelete: false,
      estTotal: null,
      salesTax: null,
      freight: null,
      discount: null,
      payment: null,
      amttotal: null,
      balance: null,
      notes: [
        {
          note: "",
        },
      ],
      productinfo: [
        {
          quantity: "",
          shipQuantity: "",
          description: "",
          unitPrice: "",
          um: "",
          amount: "",
          isTaxable: false,
        },
      ],
      desserts: [],
      editedIndex: -1,
      quantity: "",
      shipQuantity: 0,
      description: "",
      unitPrice: "",
      um: "",
      amount: "",
      isTaxable: false,
      taxAmount: null,
      phoneno:null,
      qtyrules: [
        (v) => !!v || "Required",
        (v) => v >= 0 || "Number is Expected",
        (v) => !isNaN(parseFloat(v)) || "Number is Expected",
      ],
      shipqtyrules: [
        (v) => !isNaN(parseFloat(v)) || "Number is Expected",
        (v) => v >= 0 || "Number is Expected",
      ],
      untPrice: [
        (v) => !!v || "Required",
        (v) => !isNaN(parseFloat(v)) || "Number is Expected",
        (v) => v >= 0 || "Number is Expected",
      ],
      descriptionrule: [(v) => !!v || "required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      agentCode: localStorage.getItem("agentCode"),
      showsnackbar: false,
      msg: "",
      timeout: 3000,
      isLoading: false,
    };
  },

  beforeMount() {
    if (this.orderid) {
      this.getOrder();
    }
    this.stateList();
    this.termList();
    this.shipList();
    this.getTax();
  },
   computed: {
       invoiceDate () {
      return this.formatDate(this.invoice.invoiceDate)
    },
      shippingDate () {
      return this.formatDate(this.invoice.shippingDate)
    },
    
  },
  watch: {
    phoneno() {
      //  console.log("here")
      var str = this.phoneno;
      var ind;
      if (str.length == 3) {
        ind = str.substring(0, 3);
        this.phoneno = ind + "-";
      }
      if (str.length == 8) {
        ind = str.substring(0, 7);
        this.phoneno = ind + "-";
      }
    },
    search(val) {
      // setTimeout(() => {
      //   this.getList(val);
      // }, 5000);
      this.getList(val);
    },
    searchorderId(val) {
     
      this.getidList(val);
    },
    billToCompany() {
      for (var i = 0; i < this.listItems.length; i++) {
        if (this.listItems[i]._id == this.billToCompany) {
          this.cusId = this.listItems[i].customerId;
        }
      }
      this.recentOrder = true;
      this.getrecentList();
    },
    orderId() {
      this.recentOrder = false;

      axios({
        method: "POST",
        url: "/order/view/" + this.orderId,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.orderData = response.data.data;
            this.search = this.orderData.billToCompany;
            this.invoice.billToAddress = this.orderData.billToAddress;
            this.invoice.billToCity = this.orderData.billToCity;
            this.invoice.billToStateId = this.orderData.billToStateId;
            this.invoice.billToZip = this.orderData.billToZip;
            this.invoice.shipToCompany = this.orderData.shipToCompany;
            this.invoice.shipToAddress = this.orderData.shipToAddress;
            this.invoice.shipToCity = this.orderData.shipToCity;
            this.invoice.shipToStateId = this.orderData.shipToStateId;
            this.invoice.shipToZip = this.orderData.shipToZip;
            this.invoice.email = this.orderData.email;
            this.phoneno = this.orderData.phone;
            // this.invoice.abbOrderno = this.orderData.lastOrderId.orderID;
            this.invoice.terms = this.orderData.terms;
            this.invoice.customerPO = this.orderData.customerPO;
            this.invoice.shipMethodId = this.orderData.shipMethodId;

            this.invoice.customerPO = this.orderData.customerPO;
            this.productinfo = [];
            if (this.orderData.products.length > 0)
              this.productinfo = this.orderData.products;

            if (this.productinfo.length < 1) {
              this.productinfo = [
                {
                  quantity: "",
                  shipQuantity: "",
                  description: "",
                  unitPrice: "",
                  um: "",
                  amount: "",
                  isTaxable: "",
                },
              ];
            }

            this.salesTax = this.orderData.tax;
            this.amttotal = this.orderData.totalAmount;
            this.balance = this.amttotal;
            this.estTotal = this.amttotal - this.salesTax;
            if (this.orderData.shippingDate)
              this.invoice.shippingDate = this.ChangeFormateDate(
                this.orderData.shippingDate
              );
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });

      // for (var i = 0; i < this.idlistItems.length; i++) {
      //   if (this.idlistItems[i]._id == this.orderId) {
      //     this.orderData = this.idlistItems[i];
      //   }
      // }
    },
  },
  methods: {
    notesupdate() {
      this.notes.push({
        note: "",
      });
    },
    notesremovee(index) {
      this.notes.splice(index, 1);
    },
    getOrder() {
      axios({
        method: "POST",
        url: "/order/view/" + this.orderid,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          this.recentOrder = false;
          if (response.data.status) {
            this.recentOrder = false;
            this.searchorderId = response.data.data.orderID;
            this.search = response.data.data.billToCompany;
            this.invoice.billToAddress = response.data.data.billToAddress;
            this.invoice.billToCity = response.data.data.billToCity;
            if (response.data.data.billToStateId)
              this.invoice.billToStateId = response.data.data.billToStateId._id;
            this.invoice.billToZip = response.data.data.billToZip;
            this.invoice.shipToCompany = response.data.data.shipToCompany;
            this.invoice.shipToAddress = response.data.data.shipToAddress;
            this.invoice.shipToCity = response.data.data.shipToCity;
            if (response.data.data.shipToStateId)
              this.invoice.shipToStateId = response.data.data.shipToStateId;
            this.invoice.shipToZip = response.data.data.shipToZip;
            this.invoice.email = response.data.data.email;
            this.phoneno = response.data.data.phone;
            this.invoice.orderId = response.data.data.orderID;
            if (response.data.data.terms)
              this.invoice.terms = response.data.data.terms._id;
            if (response.data.data.shipMethodId)
              this.invoice.shipMethodId = response.data.data.shipMethodId._id;
            this.invoice.customerPO = response.data.data.customerPO;
            // console.log(response.data.data.products)
            if (response.data.data.products.length > 0)
              this.productinfo = response.data.data.products;
            if (this.productinfo.length < 1) {
              this.productinfo = [
                {
                  quantity: "",
                  shipQuantity: "",
                  description: "",
                  unitPrice: "",
                  um: "",
                  amount: "",
                  isTaxable: "",
                },
              ];
            }
            this.salesTax = response.data.data.tax;
            this.amttotal = response.data.data.totalAmount;
            this.balance = this.amttotal;
            this.estTotal = this.amttotal - this.salesTax;
            if (response.data.data.shippingDate)
              this.invoice.shippingDate = this.ChangeFormateDate(
                response.data.data.shippingDate
              );
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getList(companyname) {
      this.isLoading = true;
      // this.appLoading = true;
      axios({
        method: "POST",
        url: "/order/orderID/companyname/search",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          limit: 20,
          page: 1,
          company: companyname,
        },
      })
      
        .then((response) => {
          this.isLoading = false;
          if (response.data.status) {
            
            // console.log(response.data.data)
            this.listItems = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getidList(ordId) {
      // this.appLoading = true;
      this.isLoading = true;
      axios({
        method: "POST",
        url: "/order/orderID/companyname/search",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          limit: 20,
          page: 1,
          orderID: ordId,
        },
      })
        .then((response) => {
          this.isLoading = false;
          if (response.data.status) {
            // console.log(response.data.data)
            this.idlistItems = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getrecentList() {
      // this.appLoading = true;
      axios({
        method: "POST",
        url: "/recent/order/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          customerId: this.cusId,
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.recentlist = response.data.data;
             if (this.recentlist.length == 0) {
              axios({
                method: "POST",
                url: "/order/view/" + this.billToCompany,
                headers: {
                  token: localStorage.getItem("token"),
                },
              })
                .then((response) => {
                  this.appLoading = false;
                  if (response.data.status) {
                    this.customerDtls = response.data.data;
                    this.invoice.billToAddress = this.customerDtls.billToAddress;
                    this.invoice.billToCity = this.customerDtls.billToCity;
                    // if(this. orderData.billToStateId)
                    this.invoice.billToStateId = this.customerDtls.billTostateId;
                    this.invoice.billToZip = this.customerDtls.billToZip;
                    this.invoice.shipToCompany = this.search;
                    this.invoice.shipToAddress = this.customerDtls.shipToAddress;
                    this.invoice.shipToCity = this.customerDtls.shipToCity;
                    this.invoice.shipToStateId = this.customerDtls.shipToStateId;
                    this.invoice.shipToZip = this.customerDtls.shipToZip;
                    this.invoice.email = this.customerDtls.email;
                    this.phoneno = this.customerDtls.phone;
                  }
                })
                .catch((err) => {
                  this.ServerError = true;
                  console.log(err);
                });
            }
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getrecentOrder(item) {
      // this.recentOrder = false;
      //  console.log("here");

      for (var i = 0; i < this.recentlist.length; i++) {
        if (this.recentlist[i]._id == item._id) {
          this.orderData = this.recentlist[i];
        }
      }

      this.searchorderId = this.orderData.orderID;
      this.invoice.billToAddress = this.orderData.billToAddress;
      this.invoice.billToCity = this.orderData.billToCity;
      // if(this. orderData.billToStateId)
      this.invoice.billToStateId = this.orderData.billToStateId;
      this.invoice.billToZip = this.orderData.billToZip;
      this.invoice.shipToCompany = this.orderData.shipToCompany;
      this.invoice.shipToAddress = this.orderData.shipToAddress;
      this.invoice.shipToCity = this.orderData.shipToCity;
      this.invoice.shipToStateId = this.orderData.shipToStateId;
      this.invoice.shipToZip = this.orderData.shipToZip;
      this.invoice.email = this.orderData.email;
      this.phoneno = this.orderData.phone;
      this.invoice.customerPO = this.orderData.customerPO;
      this.invoice.shipMethodId = this.orderData.shipMethodId;
      this.invoice.terms = this.orderData.terms;
      if (this.orderData.shippingDate)
        this.invoice.shippingDate = this.ChangeFormateDate(
          this.orderData.shippingDate
        );
      this.invoice.customerPO = this.orderData.customerPO;
      if (this.orderData.products.length > 0)
        this.productinfo = this.orderData.products;
      if (this.productinfo.length < 1) {
        this.productinfo = [
          {
            quantity: "",
            shipQuantity: "",
            description: "",
            unitPrice: "",
            um: "",
            amount: "",
            isTaxable: "",
          },
        ];
      }
      this.salesTax = this.orderData.tax;
      this.amttotal = this.orderData.totalAmount;
      this.balance = this.amttotal;
      this.estTotal = this.amttotal - this.salesTax;
    },
    getTax() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/tax/view/" + "5f7e94279eeed32c3477263d",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.taxAmount = response.data.data.taxAmount;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    stateList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/states",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.states = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    termList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/term/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.terms = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    shipList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/shippingMethod/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.shipvia = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    update() {
      this.productinfo.push({
        quantity: "",
        shipQuantity: "",
        description: "",
        unitPrice: "",
        um: "",
        amount: "",
        isTaxable: false,
      });
    },
    removee(index) {
      this.productinfo.splice(index, 1);
      var estimate = 0;
      var tax = 0;
      var frght = this.freight;
      for (var j = 0; j < this.productinfo.length; j++) {
        estimate = estimate + this.productinfo[j].amount;
        if (this.productinfo[j].isTaxable)
          tax = tax + this.productinfo[j].amount * (this.taxAmount / 100);
      }
      this.estTotal = estimate.toFixed(2);
      this.salesTax = tax.toFixed(2);

      this.amttotal = (estimate + tax + (frght - this.discount)).toFixed(2);

      this.balance = (this.amttotal - this.payment).toFixed(2);
    },

    balanceComputation() {
      var estimate = 0;
      var tax = 0;
      var frght = this.freight;
      for (var j = 0; j < this.productinfo.length; j++) {
        estimate = estimate + this.productinfo[j].amount;
        if (this.productinfo[j].isTaxable)
          tax = tax + this.productinfo[j].amount * (this.taxAmount / 100);
      }
      this.estTotal = estimate.toFixed(2);
      this.salesTax = tax.toFixed(2);

      this.amttotal = (estimate + tax + (frght - this.discount)).toFixed(2);

      this.balance = (this.amttotal - this.payment).toFixed(2);
    },

    amtComputation(item) {
      // console.log(item)
      var estimate = 0;
      var tax = 0;
      var frght = this.freight;
      if (item.shipQuantity > 0) {
        item.amount = item.unitPrice * item.shipQuantity;
      } else item.amount = item.unitPrice * item.quantity;

      for (var j = 0; j < this.productinfo.length; j++) {
        estimate = estimate + this.productinfo[j].amount;
        if (this.productinfo[j].isTaxable)
          tax = tax + this.productinfo[j].amount * (this.taxAmount / 100);
      }
      this.amttotal = (estimate + tax + (frght - this.discount)).toFixed(2);
      this.estTotal = estimate.toFixed(2);
      this.salesTax = tax.toFixed(2);
      this.balance = (this.amttotal - this.payment).toFixed(2);
    },

    addValidate() {
      if (this.$refs.form.validate()) this.addInvoice();
    },

    addInvoice() {
      this.appLoading = true;
      this.invoice.freight = this.freight;
      this.invoice.payment = this.payment;
      this.invoice.discount = this.discount;
      this.invoice.billToCompany = this.search;
      this.invoice.invoiceProducts = this.productinfo;
      this.invoice.billToCompany = this.search;
      this.invoice.orderId = this.searchorderId;
      this.invoice.phone = this.phoneno
      axios({
        method: "POST",
        url: "/invoice/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.invoice,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.recentOrder = false;
            this.invoceid = response.data.Iid;
            if (this.notes) this.addNotes();
            else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.$router.push("Invoice/" + this.invoceid);
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    addNotes() {
      axios({
        method: "POST",
        url: "/note/add",
        data: {
          invoiceId: this.invoceid,
          notes: this.notes,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.$notify({
              group: "appNotify",
              title: "ABB Labels",
              text: "Invoice Added!",
            });
            this.$router.push("Invoice/" + this.invoceid);
          }else{
            this.$notify({
              group: "appNotify",
              title: "ABB Labels",
              text: "Invoice Added!",
            });
            this.$router.push("Invoice/" + this.invoceid);
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    formreset() {
      this.recentOrder = false;
      // this.$refs.amtrow.reset();
      this.productinfo = [
        {
          quantity: 0,
          shipQuantity: 0,
          description: "",
          unitPrice: 0,
          um: "",
          amount: "",
          isTaxable: "",
        },
      ];
      this.estTotal = 0;
      this.salesTax = 0;
      this.balance = 0;
      this.amttotal = 0;
      this.freight = 0;
      this.payment = 0;
      this.discount = 0;
      // this.invoice.shipMethodId = "";
    },
    ChangeFormateDate(oldDate) {
      var newdateFormat = oldDate.toString().split("T");
      return newdateFormat[0];
    },
     formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
  },
};
</script>

<style >
.v-text-field--outlined.v-input--dense .v-label {
  top: 8px !important;
  font-size: 12px !important;
  font-weight: bold;
  color: black !important;
}
.v-text-field input {
  color: black !important;
}
.v-card__title {
  line-height: 1rem !important;
}
.titlestyle {
  padding-bottom: 0px !important;
}
</style>



